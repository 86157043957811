// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCJWFBfzo_M7xfUBmmVtZHgn_9tTppuAow",
  
    authDomain: "yesed-login-9b5b3.firebaseapp.com",
  
    projectId: "yesed-login-9b5b3",
  
    storageBucket: "yesed-login-9b5b3.appspot.com",
  
    messagingSenderId: "996168317539",
  
    appId: "1:996168317539:web:84a49c74495e3db54a1656"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
