import React, { useEffect, useState } from "react";
import { auth } from "./config/firebase.config";
import Login from "./Pages/Login";
import Home from "./Pages/Home";

const App = () => {
  const [firebaseToken, setFirebaseToken] = useState(null);

  useEffect(() => {
    // Check if there's a Firebase token in local storage
    const storedToken = localStorage.getItem("firebaseToken");
    if (storedToken) {
      setFirebaseToken(storedToken);
    } else {
      // Listen for changes in authentication state and update the token
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((token) => {
            // Store the token in local storage for future use
            localStorage.setItem("firebaseToken", token);
            setFirebaseToken(token);
          });
        } else {
          // No user is signed in, clear the token
          localStorage.removeItem("firebaseToken");
          setFirebaseToken(null);
        }
      });

      // Clean up the listener when the component unmounts
      return () => {
        unsubscribe();
      };
    }
  }, []);

  return (
    <div className="App">
      {firebaseToken ? <Home /> : <Login />}
    </div>
  );
};

export default App;
