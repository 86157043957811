import React from "react";
import { auth } from "../config/firebase.config";

const Home = () => {
  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful.
        console.log("User signed out successfully");
        window.location.href = "/";
      })
      .catch((error) => {
        // An error happened.
        console.error("Error during sign out:", error);
      });
  };

  return (
    <div className="Home">
      <h1>Welcome to the Home Page</h1>
      <button onClick={handleSignOut}>Sign Out</button>
    </div>
  );
};

export default Home;
