import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import '../css/Login.css';
import OtpInput from "otp-input-react";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../config/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import Logo from '../images/logoweb.png';
//import { useNavigate } from "react-router-dom";

const Login = () => {




    const [otp, setOtp] = useState("");
    const [username, setUsername] = useState('');
    const [upUsername, setUpUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [mobile, setMobile] = useState('');
    const [mobilePrompt, setMobilePrompt] = useState(false);
    const [change, setChange] = useState(false);

    function onCaptchaVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                {
                    size: "invisible",
                    callback: (response) => {
                        onSignup();
                    },
                    "expired-callback": () => {

                    },
                },
                auth
            );
        }
    }

    useEffect(() => {
        if (otpSent) {
            return () => {

            };
        }
    }, [otpSent]);

    async function onSignup() {
        setError(null);

        if (otpSent) {
            return;
        }
        setLoading(true);
        await onCaptchaVerify();

        try {
            const response = await fetch('https://yesedacademy.com/database/auth.php', {
                method: 'POST',
                body: JSON.stringify({ username, password }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data.success) {
                if (!data.mobile) {
                    // If mobile number is not present in the database, prompt user to enter it
                    setMobilePrompt(true);
                    setLoading(false);
                } else {
                    const appVerifier = new RecaptchaVerifier("recaptcha-container", {
                        size: "invisible",
                        callback: () => {

                        },
                    }, auth);

                    const formatPh = "+" + data.mobile;

                    signInWithPhoneNumber(auth, formatPh, appVerifier)

                        .then((confirmation) => {
                            console.log("Mobile No : " + formatPh);
                            setConfirmationResult(confirmation);
                            setLoading(false);
                            setShowOTP(true);
                            setOtpSent(true);
                            toast.success("OTP sent successfully!");
                        })
                        .catch((error) => {
                            console.error(error);
                            setLoading(false);
                        });

                }
            } else {
                console.log('Login failed');
                setError("Invalid username or password. Please try again.");
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setError("An error occurred. Please try again later.");
            setLoading(false);
        }
    }

    async function updateMobileAndSendOTP() {
        setError(null);
        setLoading(true);

        try {
            // Make a request to your API endpoint to update the mobile number
            const response = await fetch('https://yesedacademy.com/database/mobile.php', {
                method: 'POST',
                body: JSON.stringify({ username, password, mobile }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (data.success) {
                setMobilePrompt(false);

                // Mobile number updated successfully, now send OTP
                const appVerifier = new RecaptchaVerifier("recaptcha-container", {
                    size: "invisible",
                    callback: () => { /* callback logic */ },
                }, auth);

                const formattedMobile = "+" + mobile;

                signInWithPhoneNumber(auth, formattedMobile, appVerifier)
                    .then((confirmation) => {
                        console.log("Mobile No : " + formattedMobile);
                        setConfirmationResult(confirmation);
                        setLoading(false);
                        setShowOTP(true);
                        setOtpSent(true);
                        toast.success("OTP sent successfully!");
                    })
                    .catch((error) => {
                        console.error(error);
                        setLoading(false);
                    });
            } else {
                console.log('Mobile number update failed');
                setError("Failed to update mobile number. Please try again.");
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setError("An error occurred while updating mobile number. Please try again later.");
            setLoading(false);
        }
    }


    function onOTPVerify() {
        setError(null);
        setLoading(true);
        confirmationResult
            .confirm(otp)
            .then((res) => {
                console.log(res);
                setLoading(false);
                setOtpSent(false);
                res.user.getIdToken().then((token) => {
                    console.log("Firebase ID token:", token);
                    localStorage.setItem("firebaseToken", token);
                });

            })
            .catch((err) => {
                console.error(err);
                setError("Invalid OTP. Please try again.");
                setLoading(false);
            });
    }

    function forgotPassword() {
        setChange(true);
    }

    function updatePassword(){

        const response =  fetch('https://yesedacademy.com/database/updatePassword.php', {
                method: 'POST',
                body: JSON.stringify({ upUsername}),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = response.json();
            if (data.success) {
                setMobilePrompt(false);
                console.log('Reset link sent to the mail');
                setSuccess("An email with a link to reset your password has been sent to the email address registered with us");
                setLoading(false);
            }
            else{
                console.log('Reset link not sent');
                setError("this email is not registered with us");
                setLoading(false);
            }

    }

    function loginPage() {
        setChange(false);
    }

    function mobileUpdate(){
        setMobilePrompt(true);
    }


    return (

        <section className="bg-white-500 flex items-center justify-center h-screen">
            <div>
                <Toaster toastOptions={{ duration: 4000 }} />
                <div id="recaptcha-container"></div>





                <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
                    <div class="flex items-center justify-center ">
                        <img src={Logo} alt="logo" width={200} />
                    </div>
                    <h1 className="text-center leading-normal text-black font-medium text-3md mb-6">
                        Welcome
                    </h1>

                                {change ? (<>
                                <p class="flex text-purple-900 text-center "> Enter your email address to reset your password</p>
                                    <div className="input-group">
                                        <input className="border hover:border-purple-900 active:border-purple-900" type="text" placeholder="Username" value={upUsername} onChange={(e) => setUpUsername(e.target.value)} />
                                    </div>
                                   
                                    <button
                                        onClick={updatePassword}
                                        className="bg-purple-900 hover:bg-pink-600 w-full flex gap-1 items-center  justify-center py-2.5 text-white rounded"
                                    >
                                        {loading && (
                                            <CgSpinner size={20} className="mt-1 animate-spin" />
                                        )}
                                        <span>Submit</span>
                                    </button>
                                    <p onClick={loginPage} class="flex text-purple-900 hover:text-pink-600 hover:font-bold cursor-pointer">Login</p>
                                    </>):( <>

                    {mobilePrompt ? (<>

                        <PhoneInput country={"in"} value={mobile} onChange={setMobile} />

                        <button
                            onClick={updateMobileAndSendOTP}
                            className="bg-purple-900 hover:bg-pink-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                        >
                            {loading && (
                                <CgSpinner size={20} className="mt-1 animate-spin" />
                            )}
                            <span>Update and Verify</span>
                        </button>
                    </>
                    ) : (

                        <>
                            {showOTP ? (
                                <>
                                    <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                                        <BsFillShieldLockFill size={30} />
                                    </div>
                                    <label
                                        htmlFor="otp"
                                        className="font-bold text-xl text-white text-center"
                                    >
                                        Enter your OTP
                                    </label>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false}
                                        autoFocus
                                        className="otp-container"
                                    />
                                    <button
                                        onClick={onOTPVerify}
                                        className="bg-purple-900 hover:bg-pink-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                                    >
                                        {loading && (
                                            <CgSpinner size={20} className="mt-1 animate-spin" />
                                        )}
                                        <span>Verify OTP</span>
                                    </button>
                                    <p onClick={mobileUpdate} class="flex text-purple-900 hover:text-pink-600 hover:font-bold cursor-pointer">update Mobile Number</p>



                                </>
                            ) : (
                                <>
                                    <div className="input-group">
                                        <input className="border hover:border-purple-900 active:border-purple-900" type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </div>
                                    <div className="input-group">
                                        <input className="border hover:border-purple-900 active:border-purple-900" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <button
                                        onClick={onSignup}
                                        className="bg-purple-900 hover:bg-pink-600 w-full flex gap-1 items-center  justify-center py-2.5 text-white rounded"
                                    >
                                        {loading && (
                                            <CgSpinner size={20} className="mt-1 animate-spin" />
                                        )}
                                        <span>Login</span>
                                    </button>
                                    <p onClick={forgotPassword} class="flex text-purple-900 hover:text-pink-600 hover:font-bold cursor-pointer">Forgot Password</p>

                                </>
                            )}
                        </>
                    )}
</>
                    )}


                    {error && (
                        <div className="text-red-500 text-center my-2">
                            <span>{error}</span>
                            <span>{success}</span>
                        </div>
                    )}
                </div>




            </div>
        </section>

    );
};

export default Login;